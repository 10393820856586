@font-face {
	font-family: "Signika";
	src: url("./../fonts/Signika-Regular.ttf") format("truetype");
	font-style: "normal";
	font-weight: 400;
}

@font-face {
	font-family: "Signika";
	src: url("./../fonts/Signika-SemiBold.ttf") format("truetype");
	font-style: "normal";
	font-weight: 600;
}

html {
	-webkit-text-size-adjust: none;
	touch-action: manipulation;
}

body {
	margin: 0;
	font-family: "Signika", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	width: 100%;
	height: 100%;
}